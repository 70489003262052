import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const FloatingWhatsAppIcon = () => {
	return (
		<a
			href='https://wa.me/919073854542' // Replace with your WhatsApp number
			target='_blank'
			rel='noopener noreferrer'
			style={{
				position: 'fixed',
				bottom: '20px', // Distance from the bottom
				right: '20px', // Distance from the right
				zIndex: 1000,
				backgroundColor: '#25D366',
				color: 'white',
				borderRadius: '50%',
				width: '60px',
				height: '60px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
				cursor: 'pointer',
			}}>
			<FontAwesomeIcon icon={faWhatsapp} size='2x' />
		</a>
	);
};

export default FloatingWhatsAppIcon;
