import React, { useEffect, useState } from 'react';
import Header from '../components/resuableComponents/Header';
import Footer from '../components/resuableComponents/Footer';
import SliderPage from '../components/resuableComponents/SliderPage';
import FandomSection from '../components/resuableComponents/FandomSection';
import Carousel from '../components/resuableComponents/Carousel';
import Carousel2 from '../components/resuableComponents/Carousel2';
import { Box, Grid, useTheme, useMediaQuery, Container } from '@mui/material';
import HomeDrawer from '../components/resuableComponents/HomeDrawer';
import third from '../assets/images/3.png';
import second from '../assets/images/2.png';
import first from '../assets/images/1.png';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-brands-svg-icons';
import circle from '../assets/images/circle.svg';
import CarouselNew from '../components/resuableComponents/CarouselNew';
import Slder from '../components/resuableComponents/Slder';
import TopSelling from '../components/resuableComponents/TopSelling';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import MNavbar from '../components/resuableComponents/Navbar/MobileNav/MNavbar';
import Pricing from '../components/resuableComponents/Pricing';
import exp from '../assets/images/experience.png';
import plan from '../assets/images/planning.png';
import crm from '../assets/images/crm.png';
import order from '../assets/images/rinse.png';
import paid from '../assets/images/paid-ads.png';
import VideoPlayerWithThumbnail from '../components/resuableComponents/VideoPlayerWithThumbnail';
import FAQ from './FAQ';
const slider = [
	'https://img.freepik.com/premium-photo/young-beautiful-lady-going-shopping-holding-bags-smiling-excited-buying-with-discounts-standi_1258-129031.jpg?w=1380',
	'https://img.freepik.com/free-photo/portrat-trendy-feminine-girl-posing-with-shopping-bags-from-store-credit-card-paying-contactl_1258-127340.jpg?w=1380&t=st=1693393561~exp=1693394161~hmac=ad72ac9f57755d0415626a4a63addb9e6bd7cf9ea1049373c09454dd85069a22',
	'https://img.freepik.com/premium-vector/digital-marketing-concept-online-shopping-mobile-application_68971-367.jpg?w=1380',
	'https://img.freepik.com/free-photo/amazed-young-woman-shopaholic-holding-colorful-shopping-bags-look-amused-shop-buying-thi_1258-119761.jpg?w=1380&t=st=1693393797~exp=1693394397~hmac=d4f9c107c63c7a792fd4707b39dce4e9b13106b0bed0471880e19fe83e00787a',
];
const fandom = [
	'https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/Fandom-Naruto_P4C5r6e.jpg?format=webp&w=480&dpr=1.3',
	'https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/Fandom-Naruto_P4C5r6e.jpg?format=webp&w=480&dpr=1.3',
	'https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/Fandom-Naruto_P4C5r6e.jpg?format=webp&w=480&dpr=1.3',
];
const collect = [
	'https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/collection-tile-Banner_solids_2_O7wgu5E.jpg?format=webp&w=480&dpr=1.0',
	'https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/collection-tile-Banner_solids_2_O7wgu5E.jpg?format=webp&w=480&dpr=1.0',
	'https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/collection-tile-Banner_solids_2_O7wgu5E.jpg?format=webp&w=480&dpr=1.0',
	'https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/collection-tile-Banner_solids_2_O7wgu5E.jpg?format=webp&w=480&dpr=1.0',
];
const top = [
	'https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/looney-tunes.jpg?format=webp&w=360&dpr=1.3',
	'https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/looney-tunes.jpg?format=webp&w=360&dpr=1.3',
	'https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/looney-tunes.jpg?format=webp&w=360&dpr=1.3',
	'https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/looney-tunes.jpg?format=webp&w=360&dpr=1.3',
	'https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/looney-tunes.jpg?format=webp&w=360&dpr=1.3',
];
const data = [
	'one stop solution for all type of Reviews QR Code (i.e: google, Facebook, yahoo, tripadvisor, trustpilot, zomato etc',
	'convert any Link into a QR Code',
	'Get a top Google Business Search Ranking with high customer ratings & reviews.',
	'Eliminate Negative Reviews and Rattings from Customer',
	'Carry The card in your pocket or wallet easily',
	'Ask Customer Just one Tap or Scan the Card using their Mobile phone to give you reviews',
	'Private Dshboard to Monitor Negative Reviews of Your customer',
	'NFC Technology Enabled PVC Card with QR Code',
];
const LandingPage = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const navigate = useNavigate();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			{/* <Header /> */}
			<Navbar />
			<MNavbar />
			<div
				style={{
					paddingTop: matches ? 30 : 100,
				}}>
				<Box
					style={{
						paddingLeft: matches ? 30 : 80,
						paddingRight: matches ? 30 : 80,
					}}>
					<Grid container spacing={2}>
						<Grid item xs={12} lg={6}>
							<Box>
								<h1
									style={{
										textAlign: 'center',
										lineHeight: '1.2',
										marginBottom: '0px',
										fontSize: matches ? '24px' : '36px',
										maxWidth: '640px',
									}}>
									Sky Rocket Your Business Growth with 5⭐ Google Reviews
								</h1>

								<div
									style={{
										textAlign: 'center',
										maxWidth: '600px',
										marginBottom: '32px',
										fontSize: matches ? 18 : '24px',
										marginTop: 20,
									}}>
									Say Goodbye to Negative Reviews Forever!
								</div>
								{matches ? (
									<Box display={'flex'} justifyContent={'space-between'}>
										<div
											style={{
												width: '100%',
											}}>
											<a
												href=''
												className='ct-link black-demo-button'
												style={{
													backgroundColor: '#033355',
													borderRadius: '8px',
													padding: '16px',
													color: '#ffffff',
													fontWeight: 700,
													fontSize: '13px',
													transitionDuration: '0.5s',
													boxShadow: '-4px -4px 12px -4px #34c38e',
												}}>
												<div>Demo Card</div>
											</a>
										</div>
										<div
											style={{
												width: '100%',
												marginLeft: 10,
											}}>
											<a
												href=''
												className='ct-link green-start-free-button'
												style={{
													backgroundColor: '#34c38e',
													width: '100%',
													borderRadius: '8px',

													color: '#ffffff',
													fontWeight: 700,
													padding: '15px',
													fontSize: '15px',
													transitionDuration: '0.5s',
													boxShadow: '-4px -4px 12px -4px #34c38e',
												}}>
												<div>Create Card</div>
											</a>
											{/* <div>No Credit Card Required</div> */}
										</div>
									</Box>
								) : (
									<div
										style={{
											width: '100%',
											flexDirection: 'row',
											display: matches ? '' : 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											marginBottom: matches ? 0 : '-100px',
										}}>
										<div
											style={{
												flexDirection: 'column',
												display: 'flex',
												textAlign: 'left',
												alignItems: 'center',
												marginRight: '32px',
											}}>
											<a href='' className='ct-link black-demo-button'>
												<div>Demo Card</div>
											</a>
										</div>
										<div
											style={{
												flexDirection: 'column',
												display: 'flex',
												textAlign: 'left',
												alignItems: 'center',
												marginRight: '32px',
											}}>
											<a href='' className='ct-link green-start-free-button'>
												<div>Create Card</div>
											</a>
										</div>
									</div>
								)}
							</Box>
						</Grid>
						<Grid item xs={12} lg={6}>
							<VideoPlayerWithThumbnail />
						</Grid>
					</Grid>
				</Box>
			</div>
			{/* <section
				style={{
					// backgroundColor: '#fdf9f3',
					textAlign: 'left',
					paddingTop: 50,
					paddingLeft: matches ? 0 : 80,
					paddingRight: matches ? 0 : 80,
				}}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}>
					<div
						style={{
							position: 'relative',
							width: '100%',
							textAlign: 'left',
							alignItems: 'center',
							marginBottom: '32px',
							display: 'flex',
							flexWrap: 'nowrap',
							flexDirection: 'column',
						}}>
						<h1
							style={{
								textAlign: 'center',
								lineHeight: '1.2',
								marginBottom: '0px',
								fontSize: '64px',
								maxWidth: '640px',
							}}>
							Get More Google Reviews With AI
						</h1>
						<img
							src='https://reviewly.ai/wp-content/uploads/2023/07/Robot.png'
							style={{
								height: '100px',
								position: 'absolute',
								right: '0px',
								bottom: '0px',
							}}
						/>
						<img
							src={
								'https://reviewly.ai/wp-content/uploads/2023/07/Asterisk-Large.png'
							}
							style={{
								height: '100px',
								position: 'absolute',
								left: '0px',
								top: '0px',
								opacity: 0.2,
							}}
						/>
					</div>
					<div
						style={{
							textAlign: 'center',
							maxWidth: '600px',
							marginBottom: '32px',
							fontSize: '24px',
						}}>
						Help Your Customers Leave You Better Reviews More Often With Our
						Proven Rapid Reviews Process
					</div>
					<div
						style={{
							width: '100%',
							flexDirection: 'row',
							display: matches ? '' : 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							marginBottom: matches ? 0 : '-100px',
							// zIndex: '100',
						}}>
						<div
							style={{
								flexDirection: 'column',
								display: 'flex',
								textAlign: 'left',
								alignItems: 'center',
								marginRight: '32px',
							}}>
							<a href='' className='ct-link black-demo-button'>
								<div>Watch Demo</div>
							</a>
							<div>3 Minute Overview</div>
						</div>
						<div
							style={{
								flexDirection: 'column',
								display: 'flex',
								textAlign: 'left',
								alignItems: 'center',
								marginRight: '32px',
							}}>
							<a href='' className='ct-link green-start-free-button'>
								<div>Watch Demo</div>
							</a>
							<div>No Credit Card Required</div>
						</div>
					</div>
					<div>
						<img src='https://reviewly.ai/wp-content/uploads/2024/01/Hero-Image.png' />
					</div>
				</div>
			</section> */}
			<Box mt={matches ? '40px' : '100px'}>
				<h1
					style={{
						fontSize: matches ? 30 : 40,
						fontWeight: 'bold',
						textAlign: 'center',
					}}>
					Why Smart REVIEW QR Code?
				</h1>
				<Container style={{ marginTop: 100 }}>
					<Grid container spacing={4}>
						<Grid item xs={6} lg={4}>
							<Box
								style={{
									border: '1px solid green',
									padding: 20,
									borderRadius: 15,
								}}>
								<Box display={'flex'} justifyContent={'center'}>
									<img src={paid} style={{ height: matches ? 120 : 120 }} />
								</Box>
								<p
									style={{
										textAlign: 'center',
										marginTop: 10,
										fontSize: 16,
									}}>
									More Effective than Paid ads
								</p>
							</Box>
						</Grid>
						<Grid item xs={6} lg={4}>
							<Box
								style={{
									border: '1px solid green',
									padding: 20,
									borderRadius: 15,
								}}>
								<Box display={'flex'} justifyContent={'center'}>
									<img style={{ height: 120 }} src={order} />
								</Box>
								<p style={{ textAlign: 'center', marginTop: 10, fontSize: 16 }}>
									Get repeat Order from existing clients
								</p>
							</Box>
						</Grid>
						<Grid item xs={6} lg={4}>
							<Box
								style={{
									border: '1px solid green',
									padding: 20,
									borderRadius: 15,
								}}>
								<Box display={'flex'} justifyContent={'center'}>
									<img style={{ height: 120 }} src={exp} />
								</Box>
								<p style={{ textAlign: 'center', marginTop: 10, fontSize: 16 }}>
									Great User Experience
								</p>
							</Box>
						</Grid>
						{matches ? (
							<>
								<Grid item xs={6} lg={4}>
									<Box
										style={{
											border: '1px solid green',
											padding: 20,
											borderRadius: 15,
										}}>
										<Box display={'flex'} justifyContent={'center'}>
											<img style={{ height: 120 }} src={plan} />
										</Box>
										<p
											style={{
												textAlign: 'center',
												marginTop: 10,
												fontSize: 16,
											}}>
											Marketing Effort
										</p>
									</Box>
								</Grid>
								<Grid item xs={6} lg={4}>
									<Box
										style={{
											border: '1px solid green',
											padding: 20,
											borderRadius: 15,
										}}>
										<Box display={'flex'} justifyContent={'center'}>
											<img style={{ height: 120 }} src={crm} />
										</Box>
										<p
											style={{
												textAlign: 'center',
												marginTop: 10,
												fontSize: 16,
											}}>
											Build long-term Customer Relationship
										</p>
									</Box>
								</Grid>
							</>
						) : (
							''
						)}
					</Grid>
					{matches ? (
						''
					) : (
						<Grid container spacing={4} mt={2}>
							<Grid item xs={6} lg={2}></Grid>
							<Grid item xs={6} lg={4}>
								<Box
									style={{
										border: '1px solid green',
										padding: 20,
										borderRadius: 15,
									}}>
									<Box display={'flex'} justifyContent={'center'}>
										<img style={{ height: 120 }} src={plan} />
									</Box>
									<p
										style={{
											textAlign: 'center',
											marginTop: 10,
											fontSize: 16,
										}}>
										Marketing Effort
									</p>
								</Box>
							</Grid>
							<Grid item xs={6} lg={4}>
								<Box
									style={{
										border: '1px solid green',
										padding: 20,
										borderRadius: 15,
									}}>
									<Box display={'flex'} justifyContent={'center'}>
										<img style={{ height: 120 }} src={crm} />
									</Box>
									<p
										style={{
											textAlign: 'center',
											marginTop: 10,
											fontSize: 16,
										}}>
										Build long-term Customer Relationship
									</p>
								</Box>
							</Grid>
							<Grid item xs={6} lg={2}></Grid>
						</Grid>
					)}
				</Container>
			</Box>
			<img src={third} style={{ width: '100%', marginTop: 40 }} />
			<Box mt={matches ? '30px' : '50px'}>
				<h1
					style={{
						fontSize: matches ? 42 : 40,
						fontWeight: 'bold',
						textAlign: 'center',
					}}>
					Features of Smart Review QR Code
				</h1>
				<Container style={{ marginTop: matches ? 40 : 100 }}>
					<Grid container spacing={4}>
						{data.map((i) => {
							return (
								<Grid item xs={12} lg={6}>
									<Box
										display={'flex'}
										alignItems={'center'}
										style={{
											border: '1px solid green',
											padding: 20,
											borderRadius: 15,
										}}>
										{/* <FontAwesomeIcon icon={faCircleCheck} /> */}
										{/* <i class='fas fa-check-circle'></i> */}
										<img src={circle} style={{ height: 25 }} />
										<p
											style={{
												// textAlign: 'center',
												fontSize: 14,
												marginLeft: 10,
											}}>
											{i}
										</p>
									</Box>
								</Grid>
							);
						})}
					</Grid>
				</Container>
			</Box>
			<img src={second} style={{ width: '100%', marginTop: 40 }} />
			<Pricing />
			<img src={first} style={{ width: '100%', marginTop: 40 }} />
			{/* <video width='100%' autoplay loop muted>
				<source
					src='https://dezvolta.b-cdn.net/Happyholiday/happyhome.mp4'
					type='video/mp4'
				/>
			</video> */}
			{/* <SliderPage images={slider} /> */}
			{/* <Slder images={slider} /> */}
			{/* <FandomSection /> */}
			{/* <CarouselNew images={fandom} /> */}
			{/*  */}
			{/* <Box
				style={{
					marginTop: matches ? 15 : 50,
					marginLeft: matches ? 20 : '',
					marginBottom: matches ? 15 : 50,
				}}>
				<h1
					style={{
						textAlign: matches ? '' : 'center',
						fontSize: matches ? 20 : 30,
						fontWeight: 'bold',
					}}>
					COLECTIONS
				</h1>
			</Box>
			<CarouselNew images={collect} />
			<Box
				style={{
					padding: matches ? '' : 20,
					marginTop: matches ? 15 : 50,
					marginLeft: matches ? 20 : '',
				}}>
				<h1
					style={{
						textAlign: matches ? '' : 'center',
						fontSize: matches ? 20 : 30,
						fontWeight: 'bold',
						marginBottom: matches ? 15 : 50,
					}}>
					CATEGORY
				</h1>
				<Grid container spacing={2}>
					<Grid item xs={12} lg={4}>
						<img
							style={{ height: '100%', width: '100%' }}
							src={
								'https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/Men-Website-tile-big-one_2_DdtLisY.jpg?format=webp&w=480&dpr=1.3'
							}
						/>
					</Grid>
					<Grid item xs={12} lg={4}>
						<img
							style={{ height: '100%', width: '100%' }}
							src={
								'https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/Men-Website-tile-big-one_2_DdtLisY.jpg?format=webp&w=480&dpr=1.3'
							}
						/>
					</Grid>
					<Grid item xs={12} lg={4}>
						<img
							style={{ height: '100%', width: '100%' }}
							src={
								'https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/Men-Website-tile-big-one_2_DdtLisY.jpg?format=webp&w=480&dpr=1.3'
							}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2} style={{ marginTop: 20 }}>
					<Grid item xs={12} lg={3}>
						<img
							style={{ height: '100%', width: '100%' }}
							src={
								'https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/WebsiteTiles_Small_men-Jeans_9KUu0BW.jpg?format=webp&w=360&dpr=1.3'
							}
						/>
					</Grid>
					<Grid item xs={12} lg={3}>
						<img
							style={{ height: '100%', width: '100%' }}
							src={
								'https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/WebsiteTiles_Small_men-Jeans_9KUu0BW.jpg?format=webp&w=360&dpr=1.3'
							}
						/>
					</Grid>
					<Grid item xs={12} lg={3}>
						<img
							style={{ height: '100%', width: '100%' }}
							src={
								'https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/WebsiteTiles_Small_men-Jeans_9KUu0BW.jpg?format=webp&w=360&dpr=1.3'
							}
						/>
					</Grid>
					<Grid item xs={12} lg={3}>
						<img
							style={{ height: '100%', width: '100%' }}
							src={
								'https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/WebsiteTiles_Small_men-Jeans_9KUu0BW.jpg?format=webp&w=360&dpr=1.3'
							}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2} style={{ marginTop: 20 }}>
					<Grid item xs={12} lg={3}>
						<img
							style={{ width: '100%' }}
							src={
								'https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/WebsiteTiles_Small_Tushar_FL_EhxhyME.jpg?format=webp&w=360&dpr=1.3'
							}
						/>
					</Grid>
					<Grid item xs={12} lg={3}>
						<img
							style={{ width: '100%' }}
							src={
								'https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/WebsiteTiles_Small_men-Jeans_9KUu0BW.jpg?format=webp&w=360&dpr=1.3'
							}
						/>
					</Grid>

					<Grid item xs={12} lg={6}>
						<img
							style={{ width: '100%' }}
							src={
								'https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/horizontal-tile-men-30-08-22-copy_bByxZrU.jpg?format=webp&w=768&dpr=1.3'
							}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2} style={{ marginTop: 20 }}>
					<Grid item xs={12} lg={3}>
						<img
							style={{ width: '100%' }}
							src={
								'https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/WebsiteTiles_Small_Tushar_men-version_133_k0TOUbi.jpg?format=webp&w=360&dpr=1.3'
							}
						/>
					</Grid>
					<Grid item xs={12} lg={3}>
						<img
							style={{ width: '100%' }}
							src={
								'https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/WebsiteTiles_Small_Tushar_men-version_2_BW9rBPw.jpg?format=webp&w=360&dpr=1.3'
							}
						/>
					</Grid>

					<Grid item xs={12} lg={3}>
						<img
							style={{ width: '100%' }}
							src={
								'https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/WebsiteTiles_Small_Tushar_men-version_3_au8NsNt.jpg?format=webp&w=360&dpr=1.3'
							}
						/>
					</Grid>
					<Grid item xs={12} lg={3}>
						<img
							style={{ width: '100%' }}
							src={
								'https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/WebsiteTiles_Small_Tushar_men-version_5_ZrWJgRd.jpg?format=webp&w=360&dpr=1.3'
							}
						/>
					</Grid>
				</Grid>
			</Box>
			<Box
				style={{ marginTop: matches ? 15 : 50, marginLeft: matches ? 20 : '' }}>
				<h1
					style={{
						textAlign: matches ? '' : 'center',
						fontSize: matches ? 20 : 30,
						fontWeight: 'bold',
						marginBottom: matches ? 15 : 50,
					}}>
					STRAIGHT OUT OF CELEBRITY CLOSETS
				</h1>

				<img
					src='https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/banner-images/Hardik_23_Summer-collection_Homepage-Banner_3_RPmon3W.jpg?format=webp&w=1500&dpr=1.3'
					style={{ height: '100%', width: '100%' }}
				/>
			</Box>
			<Box style={{ marginTop: 20, marginLeft: 10, marginRight: 10 }}>
				<Box
					style={{
						marginTop: matches ? 15 : 50,
						marginLeft: matches ? 20 : '',
					}}>
					<h1
						style={{
							textAlign: matches ? '' : 'center',
							fontSize: matches ? 20 : 30,
							fontWeight: 'bold',
							marginBottom: matches ? 15 : 50,
						}}>
						TOP SELLING
					</h1>
				</Box>
				<TopSelling images={top} />
			</Box>
			<Box style={{ marginTop: 20, marginLeft: 10, marginRight: 10 }}>
				<Box
					style={{
						marginTop: matches ? 15 : 50,
						marginLeft: matches ? 20 : '',
					}}>
					<h1
						style={{
							textAlign: matches ? '' : 'center',
							fontSize: matches ? 20 : 30,
							fontWeight: 'bold',
							marginBottom: matches ? 15 : 50,
						}}>
						MEMBERSHIP
					</h1>
				</Box>
				<img
					src='https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/banner-images/Exclusive-Members-Tile_Desktop_1.jpg?format=webp&w=1500&dpr=1.3'
					style={{ maxWidth: '100%' }}
				/>
			</Box>
			<Footer /> */}
			<FAQ />
			<Footer />
		</>
	);
};

export default LandingPage;
