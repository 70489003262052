import React, { useState } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';

const FAQ = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const faqs = [
		{
			question:
				"What are the eligibility criteria to apply for Digikull's programs?",
			answer:
				'You should be in the final year of your graduation/diploma or have completed it. Additionally, you must score 50% or above in the admission test to qualify.',
		},
		{
			question: 'What is the duration of the program?',
			answer:
				'The program duration varies, but most courses are designed to be completed within 6 to 9 months.',
		},
		{
			question: 'Does Digikull provide interview preparation support?',
			answer:
				'Yes, Digikull provides comprehensive interview preparation support, including mock interviews, resume building, and more.',
		},
		{
			question: 'What is the Pay After Placement model?',
			answer:
				'The Pay After Placement model allows students to pay the program fee only after securing a job with a minimum salary as guaranteed by the institution.',
		},
		{
			question: 'What is the Pay After Placement model?',
			answer:
				'The Pay After Placement model allows students to pay the program fee only after securing a job with a minimum salary as guaranteed by the institution.',
		},
		{
			question: 'What is the Pay After Placement model?',
			answer:
				'The Pay After Placement model allows students to pay the program fee only after securing a job with a minimum salary as guaranteed by the institution.',
		},
	];

	const [openIndex, setOpenIndex] = useState(null);

	const toggleFaq = (index) => {
		setOpenIndex(openIndex === index ? null : index);
	};

	return (
		<>
			<div
				style={{
					paddingLeft: matches ? 40 : 80,
					paddingRight: matches ? 40 : 80,
					paddingTop: 50,
					paddingBottom: 50,
				}}>
				<div className='faq-container'>
					<h1>Frequently Asked Questions</h1>
					<p className='subtitle'>Got a Question? We are here to Answer!!</p>
				</div>

				<Grid container spacing={2}>
					{faqs.map((faq, index) => (
						<Grid item xs={12} lg={6}>
							<div key={index} className='faq-item'>
								<div className='faq-question' onClick={() => toggleFaq(index)}>
									<span
										className='faq-item-title'
										style={{ fontSize: matches ? 14 : 16 }}>
										{faq.question}
									</span>
									<span
										className={`faq-item-icon ${
											openIndex === index ? 'rotate' : ''
										}`}>
										+
									</span>
								</div>
								<div
									className={`faq-content ${
										openIndex === index ? 'visible' : ''
									}`}>
									<p>{faq.answer}</p>
								</div>
							</div>
						</Grid>
					))}
				</Grid>
			</div>
		</>
	);
};

export default FAQ;
