import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Footer from '../components/resuableComponents/Footer';
import Header from '../components/resuableComponents/Header';
import LoginForm from '../forms/LoginForm';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import MNavbar from '../components/resuableComponents/Navbar/MobileNav/MNavbar';
import logo from '../assets/images/rcmlogo.png';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginDispatch } from '../reducers/HomeReducer';
import Loader from '../components/resuableComponents/Loader';

const LoginPage = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { loading } = useSelector((state) => state.home);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const handleSubmit = () => {
		const formData = new FormData();
		formData.append('email', email);
		formData.append('password', password);
		console.log('formData', formData);
		dispatch(loginDispatch(formData, navigate));
		// dispatch(loginDispatch(formData, setLoading, navigate));
	};
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			{/* <Header /> */}
			<Navbar />
			<MNavbar />
			<div className='container2' style={{ padding: matches ? 20 : '' }}>
				<div className='login-box'>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<img src={logo} />
					</div>
					<h2>Welcome Back !</h2>
					<p>Sign in to continue to Review Card</p>
					<div className='form-group'>
						<label for='email'>Email</label>
						<input
							onChange={(e) => setEmail(e.target.value)}
							value={email}
							type='email'
							id='email'
							placeholder='Enter Email address'
						/>
					</div>
					<div className='form-group'>
						<label for='password'>Password</label>
						<input
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							type='password'
							id='password'
							placeholder='Enter password'
						/>
					</div>
					<div className='form-options'>
						{/* <label>
								{' '}
								<input type='checkbox' /> Remember me{' '}
							</label> */}
						<Link to='/forgotpassword'>
							<div className='forgot-password'>Forgot password?</div>
						</Link>
					</div>
					<button
						// type='submit'
						className='login-button'
						onClick={handleSubmit}>
						LOG IN
					</button>
					<footer>
						<p style={{ marginTop: 10 }}>
							2024 © All rights reserved by <strong>Review Card Maker</strong>
						</p>
					</footer>
				</div>
			</div>
			{loading ? <Loader /> : ''}
			<Footer />
		</>
	);
};

export default LoginPage;
