import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LandingPage from '../pages/LandingPage';
import LoginPage from '../pages/LoginPage';
import MywistlistPage from '../pages/MywistlistPage';
import CartPage from '../pages/CartPage';
import Product from '../pages/Product';
import ProductDetailPage from '../pages/ProductDetailPage';
import ContactUs from '../pages/ContactUs';
import AboutUs from '../pages/AboutUs';
import FAQ from '../pages/FAQ';
import TermsAndCondition from '../pages/TermsAndCondition';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Careers from '../pages/Careers';
import MyaccountPage from '../pages/OrderPage';
import Layout from './resuableComponents/Layout';
import OrderPage from '../pages/OrderPage';
import MygiftVocher from '../pages/MygiftVocher';
import MySavedCard from '../pages/MySavedCard';
import TSSPage from '../pages/TSSPage';
import SubmitDesign from '../pages/SubmitDesign';
import KidsLandingPage from '../pages/KidsLandingPage';
import WomenLandingPage from '../pages/WomenLandingPage';
import SouledArmyPage from '../pages/SouledArmyPage';
import Community from '../pages/Community';
import ProfilePage from '../pages/ProfilePage';
import MemberShipPage from '../pages/MemberShipPage';
import Whitelabel from '../pages/Whitelabel';
import PricingPlan from '../pages/PricingPlan';
import SignupPage from '../pages/SignupPage';
import ForgotPassword from '../pages/ForgotPassword';
import ReviewForm from '../pages/ReviewForm';

const MainRouter = () => {
	return (
		<div>
			<Routes>
				<Route path='/' exact element={<LandingPage />} />
				<Route path='/pricing' exact element={<PricingPlan />} />
				<Route path='/whitelabel' exact element={<Whitelabel />} />
				<Route path='/login' exact element={<LoginPage />} />
				<Route path='/signup' exact element={<SignupPage />} />
				<Route path='/forgotpassword' exact element={<ForgotPassword />} />
				<Route path='/review/:id/:name' exact element={<ReviewForm />} />
				{/* <Route path='/mywishlist' exact element={<MywistlistPage />} />
				<Route path='/cart' exact element={<CartPage />} />
				<Route path='/product' exact element={<Product />} />
				<Route path='/productdetail' exact element={<ProductDetailPage />} />
				<Route path='/contactus' exact element={<ContactUs />} />
				<Route path='/aboutus' exact element={<AboutUs />} />
				<Route path='/faq' exact element={<FAQ />} />
				<Route path='/souledarmy' exact element={<SouledArmyPage />} />
				<Route path='/community' exact element={<Community />} />
				<Route path='/careers' exact element={<Careers />} />
				<Route path='/submitdesign' exact element={<SubmitDesign />} />
				<Route path='/membership' exact element={<MemberShipPage />} /> */}
				<Route
					path='/order'
					element={
						<>
							<Layout>
								<OrderPage />
							</Layout>
						</>
					}></Route>
				{/* <Route
					path='/mygiftvocher'
					element={
						<>
							<Layout>
								<MygiftVocher />
							</Layout>
						</>
					}></Route> */}
				<Route
					path='/profile'
					element={
						<>
							<Layout>
								<ProfilePage />
							</Layout>
						</>
					}></Route>
				<Route
					path='/changepassword'
					element={
						<>
							<Layout></Layout>
						</>
					}></Route>
				{/* <Route
					path='/mysavedcard'
					element={
						<>
							<Layout>
								<MySavedCard />
							</Layout>
						</>
					}></Route> */}
				{/* <Route
					path='/tss'
					element={
						<>
							<Layout>
								<TSSPage />
							</Layout>
						</>
					}></Route> */}
				<Route path='/termcondition' exact element={<TermsAndCondition />} />
				<Route path='/privacypolicy' exact element={<PrivacyPolicy />} />
				<Route path='*' exact element={<h1>Page Not found</h1>} />
			</Routes>
		</div>
	);
};

export default MainRouter;
