import React, { useState, useRef } from 'react';
import Drop from '../../assets/images/DROPSERVICING.png';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import { useTheme, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';

const VideoPlayerWithThumbnail = () => {
	const [isPlaying, setIsPlaying] = useState(false);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const handleThumbnailClick = () => {
		setIsPlaying(true);
	};

	return (
		<div style={{ position: 'relative', marginTop: matches ? 20 : '' }}>
			{!isPlaying ? (
				<div
					style={{
						position: 'relative',
						display: 'inline-block',
						cursor: 'pointer',
					}}>
					{/* Thumbnail image */}
					<img
						src={Drop}
						alt='Video Thumbnail'
						onClick={handleThumbnailClick}
						style={{
							width: '100%',
							height: 'auto',
							borderRadius: 5,
							border: '4px solid #34c38e',
						}}
					/>

					{/* Play icon in the middle */}
					<AiOutlinePlayCircle
						onClick={handleThumbnailClick}
						style={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							width: '80px', // Adjust size as needed
							height: '80px',
							cursor: 'pointer',
						}}
					/>
				</div>
			) : (
				<div className='ratio ratio-16x9' style={{ border: '3px solid red' }}>
					<iframe
						width={matches ? '100%' : '640'}
						height={matches ? '200' : '340'}
						// src={`${hero?.result?.[0]?.videourl}?controls=1&rel=0&playsinline=0&modestbranding=1&autoplay=1&enablejsapi=1&origin=https%3A%2F%2Fresellerbano.com&widgetid=1`}
						src='https://www.youtube.com/embed/vxCwEntMucU?controls=1&rel=0&playsinline=0&modestbranding=1&autoplay=1&enablejsapi=1&origin=https%3A%2F%2Fresellerbano.com&widgetid=1'
						// src={hero?.result?.[0]?.videourl}
						title='YouTube video player'
						frameBorder='0'
						allow='autoplay; encrypted-media'
						allowFullScreen></iframe>
				</div>
			)}
		</div>
	);
};

export default VideoPlayerWithThumbnail;
