import React, { useEffect } from 'react';
import { Box, Container, Grid } from '@mui/material';
import Header from '../components/resuableComponents/Header';
import Footer from '../components/resuableComponents/Footer';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import MNavbar from '../components/resuableComponents/Navbar/MobileNav/MNavbar';

const PrivacyPolicy = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			{/* <Header /> */}
			<Navbar />
			<MNavbar />
			<Container style={{ padding: 40 }}>
				<h1 style={{ fontSize: 76, textAlign: 'center' }}>Privacy Policy</h1>
				<Box style={{ marginTop: 100, background: 'white', padding: 50 }}>
					<h1 style={{ fontSize: 20 }}>Who we are</h1>
					<br />
					<p style={{ color: '#033355' }}>
						When visitors leave comments on the site we collect the data shown
						in the comments form, and also the visitor’s IP address and browser
						user agent string to help spam detection.
					</p>
					<br />
					<h1 style={{ fontSize: 20 }}>Who we are</h1>
					<br />
					<p style={{ color: '#033355' }}>
						When visitors leave comments on the site we collect the data shown
						in the comments form, and also the visitor’s IP address and browser
						user agent string to help spam detection.
					</p>
					<br />
					<h1 style={{ fontSize: 20 }}>Who we are</h1>
					<br />
					<p style={{ color: '#033355' }}>
						When visitors leave comments on the site we collect the data shown
						in the comments form, and also the visitor’s IP address and browser
						user agent string to help spam detection.
					</p>
					<br />
					<h1 style={{ fontSize: 20 }}>Who we are</h1>
					<br />
					<p style={{ color: '#033355' }}>
						When visitors leave comments on the site we collect the data shown
						in the comments form, and also the visitor’s IP address and browser
						user agent string to help spam detection.
					</p>
					<br />
					<h1 style={{ fontSize: 20 }}>Who we are</h1>
					<br />
					<p style={{ color: '#033355' }}>
						When visitors leave comments on the site we collect the data shown
						in the comments form, and also the visitor’s IP address and browser
						user agent string to help spam detection.
					</p>
					<br />
					<h1 style={{ fontSize: 20 }}>Who we are</h1>
					<br />
					<p style={{ color: '#033355' }}>
						When visitors leave comments on the site we collect the data shown
						in the comments form, and also the visitor’s IP address and browser
						user agent string to help spam detection.
					</p>
					<br />
					<h1 style={{ fontSize: 20 }}>Who we are</h1>
					<br />
					<p style={{ color: '#033355' }}>
						When visitors leave comments on the site we collect the data shown
						in the comments form, and also the visitor’s IP address and browser
						user agent string to help spam detection.
					</p>
					<br />
					<h1 style={{ fontSize: 20 }}>Who we are</h1>
					<br />
					<p style={{ color: '#033355' }}>
						When visitors leave comments on the site we collect the data shown
						in the comments form, and also the visitor’s IP address and browser
						user agent string to help spam detection.
					</p>
				</Box>
			</Container>
			<Footer />
		</>
	);
};

export default PrivacyPolicy;
