import './App.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import MainRouter from './components/MainRouter';
// import 'bootstrap/dist/css/bootstrap.min.css';
import store from './Store';
import FloatingWhatsAppIcon from './components/resuableComponents/FloatingWhatsAppIcon';
function App() {
	return (
		<>
			<Provider store={store}>
				<BrowserRouter>
					<MainRouter />
					<FloatingWhatsAppIcon />
				</BrowserRouter>
			</Provider>
		</>
	);
}

export default App;
