import React, { useEffect } from 'react';
import { Box } from '@mui/material';

const OrderPage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div>
			<span>MY ORDERS</span>
			<Box
				mt={2}
				style={{
					textAlign: 'center',
					border: '1px solid lightgrey',
					padding: 20,
				}}>
				<span>My Orders list is empty</span>
			</Box>
		</div>
	);
};

export default OrderPage;
