import React, { useEffect, useState } from 'react';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import Footer from '../components/resuableComponents/Footer';
import { Grid, useMediaQuery, useTheme, Container } from '@mui/material';
import logo from '../assets/images/rcmlogowhite.png';
import MNavbar from '../components/resuableComponents/Navbar/MobileNav/MNavbar';
import { useDispatch, useSelector } from 'react-redux';
import { addLeadDispatch } from '../reducers/HomeReducer';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/resuableComponents/Loader';

const Whitelabel = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [name, setName] = useState('');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { loading } = useSelector((state) => state.home);
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const handleSubmit = () => {
		let form = {
			name: name,
			email: email,
			phone: phone,
			city: city,
			state: state,
		};
		dispatch(addLeadDispatch(form, navigate));
	};
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Navbar />
			<MNavbar />
			<div
				style={{
					background: 'linear-gradient(to right, #6a6eff, #ff6b6b)',
					padding: 25,
				}}>
				<Container>
					<h1 style={{ fontSize: matches ? 24 : 30, textAlign: 'center' }}>
						Hey Designer , Developer & Digital Marketing Agency Owner Do You
						want to start Your own Reviews Card Making Business In white Label
						Under Your own Brand Name ( domain Name )?
					</h1>
					<p
						className='subheading'
						style={{
							textAlign: 'center',
							color: 'white',
							fontSize: 14,
							marginTop: 20,
						}}>
						With A White Label Dashboard Under Your Own Brand? If Your Anser is
						Yes then Contact Us Now and We will Give You platform like Us Under
						Your own Domain Name in 100% Private Label and from where You can
						Manage All Of Your Business Or Clients Smoothly.
					</p>
				</Container>
				<div className='new' style={{ padding: 20 }}>
					<div
						className='containern'
						style={{ width: matches ? '100%' : '80%' }}>
						<div className='card'>
							<Grid container>
								<Grid item xs={12} lg={6}>
									<div className='left-section'>
										<div className='logo'>
											<img src={logo} alt='Reviewly Logo' />
											{/* <h1>reviewly.ai</h1> */}
										</div>
										<h2 style={{ fontSize: matches ? 14 : 18 }}>
											WHITE LABEL DASHBOARD
										</h2>
										<h3 style={{ fontSize: matches ? 20 : 40 }}>
											Enter Your Email To Request A Personalized Demo
										</h3>
										<form>
											<Grid container spacing={2}>
												<Grid item xs={12} lg={6}>
													<input
														type='text'
														placeholder='Name'
														required
														style={{ color: 'black' }}
														value={name}
														onChange={(e) => setName(e.target.value)}
													/>
												</Grid>
												<Grid item xs={12} lg={6}>
													<input
														type='email'
														style={{ color: 'black' }}
														placeholder='Email'
														required
														value={email}
														onChange={(e) => setEmail(e.target.value)}
													/>
												</Grid>
												<Grid item xs={12} lg={6}>
													<input
														type='number'
														placeholder='Phone'
														style={{ color: 'black' }}
														required
														value={phone}
														onChange={(e) => setPhone(e.target.value)}
													/>
												</Grid>
												<Grid item xs={12} lg={6}>
													<input
														type='text'
														placeholder='City'
														style={{ color: 'black' }}
														required
														value={city}
														onChange={(e) => setCity(e.target.value)}
													/>
												</Grid>
												<Grid item xs={12} lg={6}>
													<input
														type='text'
														placeholder='State'
														required
														style={{ color: 'black' }}
														value={state}
														onChange={(e) => setState(e.target.value)}
													/>
												</Grid>
											</Grid>

											<button type='button' onClick={handleSubmit}>
												Submit Now!
											</button>
										</form>
										<div className='google-rating'>
											<img
												src='https://reviewly.ai/wp-content/uploads/2023/07/Google-Reviews-48.png'
												alt='Google Logo'
											/>
											<span>★ 4.8 (2,981 Reviews)</span>
										</div>
										<p className='disclaimer'>
											By submitting your contact info, you authorize
											Reviewcardmaker.com to message you to schedule a demo and
											set up your account via email and/or SMS or WhatsApp as
											well as send promotional offers and other information
											designed for agencies.
										</p>
									</div>
								</Grid>
								<Grid item xs={12} lg={6}>
									<div className='right-section'>
										<h1
											style={{
												fontSize: matches ? 24 : 36,
												color: 'black',
												textAlign: 'center',
											}}>
											Manage All Of Your Business Or Client Locations
										</h1>
										<p
											style={{
												fontSize: matches ? 14 : 20,
												color: 'black',
												textAlign: 'center',
											}}>
											With A White Label Dashboard Under Your Own Brand With AI
											Assisted Google Reviews And Owner Responses
										</p>
										<div className='featuress' style={{ textAlign: 'center' }}>
											<button>Quick Results</button>
											<button>New Reviews</button>
											<button>Active Alerts</button>
											{/* <button>Responses</button> */}
										</div>
										<ul>
											<li style={{ color: 'black' }}>
												<strong style={{ color: 'black' }}>
													Proven Rapid Reviews Process:
												</strong>{' '}
												Generate Google Reviews quickly with our active feedback
												campaigns via SMS.
											</li>
											<li style={{ color: 'black' }}>
												<strong style={{ color: 'black' }}>
													Proven Rapid Reviews Process:
												</strong>{' '}
												Generate Google Reviews quickly with our active feedback
												campaigns via SMS.
											</li>
											<li style={{ color: 'black' }}>
												<strong style={{ color: 'black' }}>
													Proven Rapid Reviews Process:
												</strong>{' '}
												Generate Google Reviews quickly with our active feedback
												campaigns via SMS.
											</li>
										</ul>
										<div className='partners'>
											{/* <img
											style={{ background: '001a33' }}
											src='https://reviewly.ai/wp-content/uploads/2023/07/Entrepreneur-Logo-White.png'
											alt='Entrepreneur'
										/>
										<img src='inc.png' alt='Inc.' />
										<img src='msn.png' alt='MSN' />
										<img src='forbes.png' alt='Forbes' /> */}
										</div>
									</div>
								</Grid>
							</Grid>
						</div>
					</div>
				</div>
			</div>
			{loading ? <Loader /> : ''}
			<Footer />
		</>
	);
};

export default Whitelabel;
