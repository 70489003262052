import { Grid, Box, useTheme, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Footer from '../components/resuableComponents/Footer';
import Header from '../components/resuableComponents/Header';
import LoginForm from '../forms/LoginForm';
import logoss from '../assets/images/rcmlogo.png';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import MNavbar from '../components/resuableComponents/Navbar/MobileNav/MNavbar';
import { useDispatch } from 'react-redux';
import { userRegistrationDispatch } from '../reducers/HomeReducer';

const SignupPage = () => {
	const [firstname, setfirstname] = useState('');
	const [lastname, setlastname] = useState('');
	const [email, setemail] = useState('');
	const [password, setpassword] = useState('');
	const [mobile, setmobile] = useState('');
	const [country, setcountry] = useState('');
	const [state, setstate] = useState('');
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [city, setcity] = useState('');
	const [address, setaddress] = useState('');
	const [agency_name, setagency_name] = useState('');
	const [site_url, setsite_url] = useState('');
	const [agency_pan, setagency_pan] = useState('');
	const [logo, setLogo] = useState('');
	const dispatch = useDispatch();
	const handleSubmit = () => {
		let form = {
			firstname: firstname,
			lastname: lastname,
			email: email,
			password: password,
			password_confirmation: password,
			mobile: mobile,
			country: country,
			state: state,
			city: city,
			address: address,
			agency_name: agency_name,
			site_url: site_url,
			agency_pan: agency_pan,
			pimage: logo,
			role: 'partner',
			status: true,
		};
		const formData = new FormData();
		formData.append('firstname', firstname);
		formData.append('lastname', lastname);
		formData.append('email', email);
		formData.append('password', password);
		formData.append('password_confirmation', password);
		formData.append('mobile', mobile);
		formData.append('country', country);
		formData.append('state', state);
		formData.append('city', city);
		formData.append('address', address);
		formData.append('agency_name', agency_name);
		formData.append('site_url', site_url);
		formData.append('agency_pan', agency_pan);
		formData.append('pimage', logo);
		formData.append('role', 'partner');
		formData.append('status', true);

		var options = {
			key: 'rzp_test_2ZqKzJLqTKOQsY',
			key_secret: 'tUHmb4gdwNB8AD7xkTSdMJme',
			amount: Number(500) * 100,
			currency: 'INR',
			name: 'STARTUP_PROJECTS',
			description: 'for testing purpose',
			handler: function (response) {
				// alert(response.razorpay_payment_id);
				if (response.razorpay_payment_id) {
					dispatch(userRegistrationDispatch(formData));
				}
			},
			prefill: {
				name: `${firstname} ${lastname}`,
				email: email,
				contact: mobile,
			},
			notes: {
				address: 'Razorpay Corporate office',
			},
			theme: {
				color: '#3399cc',
			},
		};
		var pay = new window.Razorpay(options);
		pay.open();
	};
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			{/* <Header /> */}
			<Navbar />
			<MNavbar />
			<div
				className='container3'
				style={{
					padding: matches ? '20px' : '40px',
				}}>
				<div className='login-box2'>
					<div className='logo'>
						<Box display='flex' justifyContent='center'>
							<img src={logoss} />
						</Box>
					</div>
					<div class='container mt-5'>
						<h2 class='text-center'>Registration Form</h2>
						<br />
						<form id='registrationForm'>
							<Grid container spacing={2}>
								<Grid item xs={12} lg={6}>
									<input
										onChange={(e) => setfirstname(e.target.value)}
										value={firstname}
										type='text'
										class='form-control'
										id='firstname'
										name='firstname'
										placeholder='First Name*'
										required
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<input
										onChange={(e) => setlastname(e.target.value)}
										value={lastname}
										type='text'
										class='form-control'
										id='lastname'
										placeholder='Last Name*'
										name='lastname'
										required
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<input
										onChange={(e) => setemail(e.target.value)}
										value={email}
										type='email'
										class='form-control'
										id='email'
										placeholder='Email*'
										name='email'
										required
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<input
										onChange={(e) => setpassword(e.target.value)}
										value={password}
										type='password'
										class='form-control'
										id='password'
										name='password'
										placeholder='Password*'
										required
										minlength='8'
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<input
										onChange={(e) => setmobile(e.target.value)}
										value={mobile}
										type='tel'
										class='form-control'
										id='mobile'
										name='mobile'
										placeholder='Mobile*'
										required
										pattern='^\d{10}$'
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<input
										onChange={(e) => setcountry(e.target.value)}
										value={country}
										type='text'
										class='form-control'
										id='country'
										placeholder='Country*'
										name='country'
										required
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<input
										onChange={(e) => setstate(e.target.value)}
										value={state}
										type='text'
										class='form-control'
										id='state'
										placeholder='State*'
										name='state'
										required
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<input
										onChange={(e) => setcity(e.target.value)}
										value={city}
										type='text'
										class='form-control'
										id='city'
										placeholder='City*'
										name='city'
										required
									/>
								</Grid>

								<Grid item xs={12} lg={6}>
									<input
										onChange={(e) => setagency_name(e.target.value)}
										value={agency_name}
										type='text'
										class='form-control'
										placeholder='Agency Name'
										id='agency_name'
										name='agency_name'
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<input
										onChange={(e) => setsite_url(e.target.value)}
										value={site_url}
										placeholder='Site URL*'
										type='url'
										class='form-control'
										id='site_url'
										name='site_url'
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<input
										onChange={(e) => setagency_pan(e.target.value)}
										value={agency_pan}
										type='text'
										placeholder='Agency PAN'
										class='form-control'
										id='agency_pan'
										name='agency_pan'
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<input
										onChange={(event) => setLogo(event.currentTarget.files[0])}
										type='file'
										class='form-control'
										id='logo'
										placeholder='Upload Logo*'
										name='logo'
										accept='image/*'
										required
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<textarea
										onChange={(e) => setaddress(e.target.value)}
										value={address}
										class='form-control'
										id='address'
										placeholder='Address*'
										name='address'
										rows='3'
										required></textarea>
								</Grid>
							</Grid>
							<button
								style={{ marginTop: 20 }}
								type='submit'
								class='btn btn-primary'
								onClick={handleSubmit}>
								Register
							</button>
						</form>
					</div>
					<br />
					<footer>
						<p>
							2024 © All rights reserved by <strong>Review Card</strong>
						</p>
					</footer>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default SignupPage;
