import React from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Grid, Box, Button, Divider } from '@mui/material';
import { useSelector } from 'react-redux';

const MyProfileForm = () => {
	const handleFormSubmit = () => {};
	const { loginInfo } = useSelector((state) => state.home);
	return (
		<Formik
			enableReinitialize={true}
			initialValues={{
				firstname: loginInfo?.result?.firstname,
				lastname: loginInfo?.result?.lastname,
				mobile: loginInfo?.result?.mobile,
			}}
			onSubmit={handleFormSubmit}>
			{(formik) => {
				const { errors, setFieldValue } = formik;
				return (
					<Form>
						<Box style={{ padding: 10 }}>
							{/* <Box style={{ border: '1px solid lightgrey', padding: 30 }}>
								<Grid container spacing={4}>
									<Grid item xs={6}>
										<span style={{ color: 'rgba(88, 89, 91, 1)' }}>
											Email Id
										</span>
										<br></br>
										<Field
											name='email'
											type='text'
											placeHolder='Email'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.email ? '2px solid red' : '1px solid',
												background: '#F6F6F6',
												marginTop: 10,
											}}
											inputProps={{
												style: { padding: 12 },
											}}
										/>
										<br />
										{errors?.email ? (
											<span style={{ color: 'red', fontSize: 12 }}>
												{errors?.email}
											</span>
										) : null}
									</Grid>
									<Grid item xs={6}>
										<span style={{ color: 'rgba(88, 89, 91, 1)' }}>
											Password
										</span>
										<br></br>
										<Field
											name='email'
											type='text'
											placeHolder='Password'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.email ? '2px solid red' : '1px solid',
												background: '#F6F6F6',
												marginTop: 10,
											}}
											inputProps={{
												style: { padding: 12 },
											}}
										/>
										<br />
										{errors?.email ? (
											<span style={{ color: 'red', fontSize: 12 }}>
												{errors?.email}
											</span>
										) : null}
									</Grid>
								</Grid>
							</Box> */}
							<Box style={{ border: '1px solid lightgrey', padding: 30 }}>
								<div style={{ color: 'rgba(88, 89, 91, 1)' }}>
									General Information
								</div>
								<Divider style={{ marginTop: 20, marginBottom: 20 }} />
								<Grid
									container
									style={{ marginTop: 5, padding: 10 }}
									spacing={4}>
									<Grid item xs={6}>
										<span style={{ color: 'rgba(88, 89, 91, 1)' }}>
											First Name*
										</span>
										<br></br>
										<Field
											name='firstname'
											type='text'
											placeHolder='Enter First Name'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.firstname
													? '2px solid red'
													: '1px solid',
												marginTop: 10,
											}}
											inputProps={{
												style: { padding: 12 },
											}}
										/>
										<br />
										{errors?.firstname ? (
											<span style={{ color: 'red', fontSize: 12 }}>
												{errors?.firstname}
											</span>
										) : null}
									</Grid>
									<Grid item xs={6}>
										<span style={{ color: 'rgba(88, 89, 91, 1)' }}>
											Last Name*
										</span>
										<br></br>
										<Field
											name='lastname'
											type='text'
											placeHolder='Enter Last Name'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.lastname
													? '2px solid red'
													: '1px solid',
												marginTop: 10,
											}}
											inputProps={{
												style: { padding: 12 },
											}}
										/>
										<br />
										{errors?.lastname ? (
											<span style={{ color: 'red', fontSize: 12 }}>
												{errors?.lastname}
											</span>
										) : null}
									</Grid>
									<Grid item xs={6}>
										<span style={{ color: 'rgba(88, 89, 91, 1)' }}>
											Mobile Number *
										</span>
										<br></br>
										<Field
											name='mobile'
											type='text'
											placeHolder='Enter Mobile Number'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.mobile ? '2px solid red' : '1px solid',
												marginTop: 10,
											}}
											inputProps={{
												style: { padding: 12 },
											}}
										/>
										<br />
										{errors?.mobile ? (
											<span style={{ color: 'red', fontSize: 12 }}>
												{errors?.mobile}
											</span>
										) : null}
									</Grid>
								</Grid>
							</Box>
						</Box>

						<Grid container>
							<Grid item xs={3}></Grid>
							<Grid item xs={3}>
								<Button
									type='submit'
									style={{
										background: 'rgb(239, 64, 80)',
										color: 'white',
										marginTop: 30,
										// padding: 16,
										borderRadius: 5,
										width: '100%',
									}}>
									Save
								</Button>
							</Grid>
							<Grid item xs={3}></Grid>

							<Grid item xs={3}></Grid>
						</Grid>
					</Form>
				);
			}}
		</Formik>
	);
};

export default MyProfileForm;
