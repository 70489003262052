import React, { Fragment } from 'react';
import g1 from '../../assets/images/googleplay.png';
import g2 from '../../assets/images/appleplay.png';
import g3 from '../../assets/images/original.png';
import logo from '../../assets/images/rcmlogowhite.png';
import { AiFillFacebook, AiFillYoutube } from 'react-icons/ai';
import { ImTwitter, ImInstagram } from 'react-icons/im';
import { Box, Grid, useTheme, useMediaQuery, Container } from '@mui/material';
import { Link } from 'react-router-dom';

const Footer = () => {
	return (
		<Fragment>
			<footer className='footer'>
				<Container>
					<Grid container spacing={2}>
						<Grid item xs={12} lg={4}>
							<Box display={'flex'} justifyContent={'center'}>
								<img src={logo} style={{ height: 50 }} />
							</Box>
						</Grid>
						<Grid item xs={12} lg={8}>
							<Grid container spacing={2}>
								<Grid item xs={12} lg={3}>
									<div className='footer-column'>
										<h4>Main Menu</h4>
										<Link to='/pricing'>Pricing</Link>
										<Link to='/whitelabel'>White Label</Link>
									</div>
								</Grid>
								<Grid item xs={12} lg={3}>
									<div className='footer-column'>
										<h4>Support</h4>
										<a href='#'>Support Center</a>
										<a href='#'>Knowledge Base</a>
										<a href='#'>Submit A Ticket</a>
									</div>
								</Grid>
								<Grid item xs={12} lg={3}>
									<div className='footer-column'>
										<h4>Access</h4>
										<a href='#'>Store</a>
										<Link to='/login'>Login</Link>
										<a href='#'>Become A Partner</a>
									</div>
								</Grid>
								<Grid item xs={12} lg={3}>
									<div className='footer-column'>
										<h4>Disclosures</h4>
										<Link to='/privacypolicy'>Privacy Policy</Link>
										<Link to='/termcondition'>Terms and Condition</Link>
									</div>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</footer>
			<div className='footer-bottom'>
				<p>&copy; 2024 Review Card Maker</p>
			</div>
		</Fragment>
	);
};

export default Footer;
