import { Box, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import Footer from '../components/resuableComponents/Footer';
import Header from '../components/resuableComponents/Header';
import LoginForm from '../forms/LoginForm';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import MNavbar from '../components/resuableComponents/Navbar/MobileNav/MNavbar';
import logo from '../assets/images/rcmlogo.png';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			{/* <Header /> */}
			<Navbar />
			<MNavbar />
			<div className='container2'>
				<div className='login-box'>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<img src={logo} />
					</div>
					<h2>Reset Password !</h2>
					{/* <p>Sign in to continue to Review Card</p> */}
					<form>
						<div className='form-group'>
							<label for='email'>Email</label>
							<input
								type='email'
								id='email'
								placeholder='Enter Email address'
							/>
						</div>
						{/* <div className='form-options'>
							<div></div>
							
						</div> */}
						<Box display='flex' justifyContent='space-between'>
							<Box></Box>
							<Box display={'flex'}>
								<p>Already have account?</p>
								<div className='forgot-password'>
									<Link to='/login'>
										<p style={{ marginLeft: 5 }}>Login</p>
									</Link>
								</div>
							</Box>
						</Box>
						<button type='submit' className='login-button'>
							Submit
						</button>
					</form>
					<footer>
						<p>
							2024 © All rights reserved by <strong>Review Card</strong>
						</p>
					</footer>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default ForgotPassword;
