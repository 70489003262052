import React, { useEffect } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Pricing = () => {
	const navigate = useNavigate();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<div className='pricing-container'>
				<h1 style={{ fontSize: matches ? 32 : 60 }}>
					Get Your Smart Review Card at One Time Cost
				</h1>
				<p className='subheading'>
					Unlock The Magic of Our Smart Review Card | Say Good Bye to Negative
					Reviews | Get More Positive Reviews
				</p>

				{/* <div className='toggle-container'>
					<span>Monthly</span>
					<label className='switch'>
						<input type='checkbox' id='billing-toggle' />
						<span className='slider round'></span>
					</label>
					<span>Yearly</span>
				</div> */}

				<div className='plans' style={{ marginTop: 50 }}>
					<Grid container spacing={2}>
						<Grid item xs={12} lg={4}>
							<div className='plan starter'>
								<div className='bacplan'>
									<h2>STARTER</h2>
								</div>
								<div className='newplan'>
									<p className='price'>₹999</p>
									{/* <p className='description'>
										For businesses looking to
										<strong>ONLY MONITOR AND RESPOND</strong> to new Google
										Reviews. This works great for businesses that do not collect
										mobile numbers or use the Reviewly Plate.
									</p> */}
									<ul className='features'>
										<li>NFC Enabled PVC QR Card</li>
										<li>Request Customer Feedback By scanning QR</li>
										<li>
											Request Customer Feedback By JUST 1 Tap on NFC Card.
										</li>
										<li>No Negative Reviews & Feedback</li>
										<li>Private Dashboard to Monitor Negative Reviews</li>
										<li>Unlimited Scan By Customer</li>
										<li>
											Covert Any Kind of Link into QR Code ( Unlimited) from
											Admin Dashboard
										</li>
										<li>QTY-3 NFC PVC Smart Google Reviews Card</li>
									</ul>
									<div className='support-options'>
										<h3>Support Options</h3>
										<ul>
											<li className='check'>
												Knowledge Base And Whatsapp/Email Support
											</li>
											<li className='check'>
												Live Support Via Scheduled Google meet
											</li>
										</ul>
										<button
											className='cta-button'
											onClick={() => navigate('/signup')}>
											ORDER NOW
										</button>
									</div>
								</div>
							</div>
						</Grid>
						<Grid item xs={12} lg={4}>
							<div className='plan starter'>
								<div className='bacplan'>
									<h2>ECONOMY</h2>
								</div>
								<div className='newplan'>
									<p className='price'>₹1499</p>

									<ul className='features'>
										<li>NFC Enabled PVC QR Card</li>
										<li>Request Customer Feedback By scanning QR</li>
										<li>
											Request Customer Feedback By JUST 1 Tap on NFC Card.
										</li>
										<li>No Negative Reviews & Feedback</li>
										<li>Private Dashboard to Monitor Negative Reviews</li>
										<li>Unlimited Scan By Customer</li>
										<li>
											Covert Any Kind of Link into QR Code ( Unlimited) from
											Admin Dashboard
										</li>
										<li>QTY-3 NFC PVC Smart Google Reviews Card</li>
									</ul>
									<div className='support-options'>
										<h3>Support Options</h3>
										<ul>
											<li className='check'>
												Knowledge Base And Whatsapp/Email Support
											</li>
											<li className='check'>
												Live Support Via Scheduled Google meet
											</li>
										</ul>
										<button
											className='cta-button'
											onClick={() => navigate('/signup')}>
											ORDER NOW
										</button>
									</div>
								</div>
							</div>
						</Grid>
						<Grid item xs={12} lg={4}>
							<div className='plan starter'>
								<div className='bacplan'>
									<h2>PRIME</h2>
								</div>
								<div className='newplan'>
									<p className='price'>₹1999</p>
									<ul className='features'>
										<li>NFC Enabled PVC QR Card</li>
										<li>Request Customer Feedback By scanning QR</li>
										<li>
											Request Customer Feedback By JUST 1 Tap on NFC Card.
										</li>
										<li>No Negative Reviews & Feedback</li>
										<li>Private Dashboard to Monitor Negative Reviews</li>
										<li>Unlimited Scan By Customer</li>
										<li>
											Covert Any Kind of Link into QR Code ( Unlimited) from
											Admin Dashboard
										</li>
										<li>QTY-3 NFC PVC Smart Google Reviews Card</li>
									</ul>
									<div className='support-options'>
										<h3>Support Options</h3>
										<ul>
											<li className='check'>
												Knowledge Base And Whatsapp/Email Support
											</li>
											<li className='check'>
												Live Support Via Scheduled Google meet
											</li>
										</ul>
										<button
											className='cta-button'
											onClick={() => navigate('/signup')}>
											ORDER NOW
										</button>
									</div>
								</div>
							</div>
						</Grid>
					</Grid>
				</div>
			</div>
		</>
	);
};

export default Pricing;
