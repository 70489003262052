import React from 'react';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import Pricing from '../components/resuableComponents/Pricing';
import Footer from '../components/resuableComponents/Footer';
import MNavbar from '../components/resuableComponents/Navbar/MobileNav/MNavbar';

const PricingPlan = () => {
	return (
		<>
			<Navbar />
			<MNavbar />
			<Pricing />
			<Footer />
		</>
	);
};

export default PricingPlan;
